import React from "react";
import colors from "@styles/variables/compareCRMPricesVariables.module.scss";

import ImproveCustomerServiceIcon from "../icons/improveCustomerService";
import IncreaseInSalesIcon from "../icons/increaseInSales";
import BetterAnalyticsIcon from "../icons/betterAnalytics";
import HigherEfficiencyIcon from "../icons/higherEfficiency";
import BetterKnowledgeSharingIcon from "../icons/betterKnowledgeSharing";
import MoreTransparencyIcon from "../icons/moreTransparency";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Improves Customer Service",
        description:
            "With a CRM, managing all your contacts and consolidating lead and customer information becomes easier, giving you access to essential data to better understand your customers.",
        icon: <ImproveCustomerServiceIcon />,
    },
    {
        title: "Boosts Sales",
        description:
            "Streamlining and automating your sales process, building a sales pipeline, and analyzing sales data lead to increased productivity and sales.",
        icon: <IncreaseInSalesIcon />,
    },
    {
        title: "Better Analytics",
        description:
            "Analytical CRM tools make your data available, intelligible, and relevant to your business needs. You can make informed decisions with detailed reports on sales, finance, and marketing data.",
        icon: <BetterAnalyticsIcon />,
    },
    {
        title: "Increase Efficiency",
        description:
            "Centralizing your primary business functions in one place leads to better workflow, easier collaboration between team members, and improved project management.",
        icon: <HigherEfficiencyIcon />,
    },
    {
        title: "Facilitate Knowledge Sharing",
        description:
            "A CRM system promotes better knowledge sharing, eliminating miscommunications and information gaps that can lead to wasted time.",
        icon: <BetterKnowledgeSharingIcon />,
    },
    {
        title: "Enhance Transparency",
        description:
            "A CRM system fosters greater organizational transparency by assigning tasks, displaying work, and clarifying each employee's position and authority.",
        icon: <MoreTransparencyIcon />,
    },
];

export default function WhyUs(): JSX.Element {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    return (
        <>
            <FeatureList
                featureList={whyUsList}
                mainTitle="Benefits of CRM: Improving Your Business Performance"
                classes={{
                    mainSectionClasses: "py-2",
                    mainTitleClasses:
                        "text-center font-bold text-xl lg:text-4xl mb-14",
                    innerSectionClasses: "lg:flex flex-wrap justify-center",
                    oneBoxClasses:
                        "text-center mb-12 lg:mb-20 w-full lg:w-1/3 ",
                    iconClasses: "children:mx-auto",
                    titleClasses: "font-medium text-lg lg:text-xl mt-6",
                    descriptionClasses:
                        "font-light text-sm text-center w-4/5 mx-auto mt-2",
                }}
                colors={{
                    mainTitleColor: colors.whyUsTitle,
                    titleColor: colors.whyUsItemTitle,
                    descriptionColor: colors.whyUsItemBrief,
                }}
            />
            <button
                onClick={() => scrollToTop()}
                style={{ backgroundColor: "rgb(0, 86, 112" }}
                className={` text-white py-4 sm:px-11 px-4 text-sm sm:text-lg font-semibold rounded-lg block mx-auto mb-20 hover:-translate-y-2 hover:transition-all transition-all`}
            >
                Get My Free CRM Quote Now
            </button>
        </>
    );
}
