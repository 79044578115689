import React, { ReactElement } from "react";

export default function BetterAnalyticsIcon(): ReactElement {
    return (
        <svg
            width="81"
            height="78"
            viewBox="0 0 81 78"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse cx="31" cy="43.5" rx="28" ry="27.5" fill="#F5FDFF" />
            <path
                d="M18.3398 13.6414C18.0676 13.6414 17.8066 13.5334 17.6141 13.341C17.4216 13.1487 17.3134 12.8878 17.3134 12.6158V11.3469H16.0264C15.7542 11.3469 15.4931 11.2388 15.3006 11.0465C15.1081 10.8541 15 10.5933 15 10.3212C15 10.0492 15.1081 9.78834 15.3006 9.59599C15.4931 9.40364 15.7542 9.29559 16.0264 9.29559H17.3134V8.02566C17.3134 7.75364 17.4216 7.49276 17.6141 7.30041C17.8066 7.10806 18.0676 7 18.3398 7C18.6121 7 18.8731 7.10806 19.0656 7.30041C19.2581 7.49276 19.3662 7.75364 19.3662 8.02566V9.29421H20.6547C20.9269 9.29421 21.188 9.40227 21.3804 9.59462C21.5729 9.78697 21.6811 10.0478 21.6811 10.3199C21.6811 10.5919 21.5729 10.8528 21.3804 11.0451C21.188 11.2375 20.9269 11.3455 20.6547 11.3455H19.3676V12.6144C19.3678 12.7493 19.3413 12.883 19.2898 13.0077C19.2382 13.1323 19.1625 13.2456 19.067 13.341C18.9715 13.4364 18.8581 13.5121 18.7334 13.5636C18.6086 13.6152 18.4749 13.6416 18.3398 13.6414V13.6414Z"
                fill="#F15B4B"
            />
            <path
                d="M77.4545 48.4853C77.4051 48.1456 77.2559 47.8283 77.026 47.5733C76.796 47.3184 76.4955 47.1374 76.1626 47.0531C75.8296 46.9688 75.4792 46.9851 75.1555 47.1C74.8318 47.2148 74.5495 47.4229 74.3442 47.6981C74.1389 47.9732 74.02 48.3031 74.0023 48.6458C73.9846 48.9886 74.0691 49.3289 74.245 49.6236C74.4209 49.9184 74.6804 50.1544 74.9905 50.3019C75.3007 50.4493 75.6476 50.5015 75.9875 50.4518C76.213 50.4189 76.4299 50.342 76.6257 50.2254C76.8215 50.1088 76.9924 49.9548 77.1286 49.7722C77.2648 49.5896 77.3637 49.3819 77.4196 49.1611C77.4756 48.9403 77.4874 48.7106 77.4545 48.4853V48.4853Z"
                fill="#F15B4B"
            />
            <path
                d="M78.583 53.1494C78.5601 52.9939 78.4916 52.8486 78.3861 52.732C78.2807 52.6153 78.143 52.5326 77.9904 52.4941C77.8379 52.4557 77.6774 52.4633 77.5291 52.5159C77.3809 52.5686 77.2516 52.664 77.1576 52.7901C77.0636 52.9161 77.0092 53.0672 77.0011 53.2242C76.993 53.3812 77.0316 53.537 77.1121 53.6721C77.1927 53.8071 77.3114 53.9153 77.4534 53.9829C77.5954 54.0505 77.7543 54.0746 77.91 54.052C78.1188 54.0215 78.3068 53.9095 78.4329 53.7405C78.559 53.5715 78.6127 53.3593 78.5824 53.1508L78.583 53.1494Z"
                fill="#F15B4B"
            />
            <path
                d="M17.1475 43.4829C17.1353 43.4829 17.1237 43.4877 17.1152 43.4962C17.1066 43.5048 17.1018 43.5163 17.1018 43.5284V52.8586C17.1018 52.8706 17.1066 52.8822 17.1152 52.8907C17.1237 52.8993 17.1353 52.9041 17.1475 52.9041H24.6659C24.6719 52.9041 24.6778 52.9029 24.6833 52.9006C24.6889 52.8983 24.6938 52.8949 24.698 52.8907C24.7022 52.8865 24.7055 52.8814 24.7078 52.8759C24.71 52.8704 24.7111 52.8645 24.711 52.8586V43.5284C24.7111 43.5225 24.71 43.5166 24.7078 43.511C24.7055 43.5055 24.7022 43.5005 24.698 43.4963C24.6938 43.492 24.6889 43.4887 24.6833 43.4864C24.6778 43.4841 24.6719 43.4829 24.6659 43.4829H17.1475ZM24.6659 54.0006H17.1475C16.8431 54.0003 16.5514 53.8798 16.3362 53.6654C16.1211 53.4511 16.0002 53.1605 16 52.8574V43.5273C16.0003 43.2243 16.1213 42.9337 16.3364 42.7194C16.5515 42.5051 16.8432 42.3845 17.1475 42.384H24.6659C24.9706 42.3842 25.2628 42.5048 25.4782 42.7194C25.6935 42.934 25.8145 43.225 25.8145 43.5284V52.8586C25.8144 53.1616 25.6935 53.4522 25.4783 53.6666C25.2632 53.8809 24.9714 54.0015 24.6671 54.0018L24.6659 54.0006Z"
                fill="#005670"
            />
            <path
                d="M47.9381 36.3524C47.9259 36.3524 47.9143 36.3572 47.9058 36.3657C47.8972 36.3742 47.8924 36.3858 47.8924 36.3979V52.8574C47.8924 52.8695 47.8972 52.881 47.9058 52.8896C47.9143 52.8981 47.9259 52.9029 47.9381 52.9029H55.4565C55.4687 52.9029 55.4803 52.8981 55.4889 52.8896C55.4974 52.881 55.5022 52.8695 55.5022 52.8574V36.3979C55.5022 36.3858 55.4974 36.3742 55.4889 36.3657C55.4803 36.3572 55.4687 36.3524 55.4565 36.3524H47.9381ZM55.4565 54.0006H47.9381C47.6337 54.0003 47.3419 53.8798 47.1266 53.6654C46.9114 53.4511 46.7903 53.1605 46.79 52.8574V36.3979C46.7903 36.0948 46.9114 35.8041 47.1266 35.5898C47.3419 35.3755 47.6337 35.2549 47.9381 35.2546H55.4565C55.7609 35.2549 56.0526 35.3755 56.2678 35.5898C56.4829 35.8042 56.6038 36.0948 56.604 36.3979V52.8574C56.6038 53.1605 56.4829 53.4511 56.2678 53.6654C56.0526 53.8798 55.7609 54.0003 55.4565 54.0006Z"
                fill="#005670"
            />
            <path
                d="M67.0922 11.0977C66.2574 11.0987 65.4571 11.4293 64.8668 12.0171C64.2766 12.6049 63.9446 13.4018 63.9436 14.2331C63.9427 14.8553 64.1291 15.4636 64.4786 15.9793C64.6882 16.2899 64.9518 16.5606 65.2571 16.7787C65.7908 17.1635 66.4333 17.3699 67.0922 17.3685C67.9269 17.3676 68.7272 17.0369 69.3175 16.4491C69.9078 15.8613 70.2398 15.0644 70.2407 14.2331C70.2398 13.4018 69.9078 12.6049 69.3175 12.0171C68.7272 11.4293 67.9269 11.0987 67.0922 11.0977V11.0977ZM67.0922 18.4662C66.202 18.4682 65.334 18.1894 64.6128 17.6697C64.2019 17.3755 63.8468 17.0108 63.5642 16.5927C63.0918 15.8952 62.84 15.0727 62.8413 14.2314C62.8425 13.1091 63.2908 12.0331 64.0877 11.2395C64.8846 10.4459 65.9651 9.99951 67.0922 9.99829C68.2192 9.99951 69.2997 10.4459 70.0966 11.2395C70.8935 12.0331 71.3418 13.1091 71.343 14.2314C71.3418 15.3537 70.8935 16.4297 70.0966 17.2233C69.2997 18.0169 68.2192 18.4633 67.0922 18.4645V18.4662Z"
                fill="#005670"
            />
            <path
                d="M20.9068 32.9652C20.072 32.9662 19.2718 33.2969 18.6816 33.8847C18.0914 34.4724 17.7593 35.2693 17.7582 36.1005C17.7593 36.9318 18.0914 37.7286 18.6816 38.3164C19.2718 38.9042 20.072 39.2348 20.9068 39.2359C21.7416 39.235 22.5419 38.9044 23.1323 38.3166C23.7226 37.7288 24.0548 36.9319 24.0559 36.1005C24.0567 35.6541 23.9608 35.2127 23.7748 34.8064C23.6194 34.4659 23.4039 34.156 23.1386 33.8913C22.8468 33.5971 22.4991 33.3638 22.1159 33.2048C21.7326 33.0458 21.3214 32.9643 20.9062 32.9652H20.9068ZM20.9068 40.3336C19.7798 40.3323 18.6995 39.8858 17.9027 39.0922C17.1059 38.2987 16.6577 37.2228 16.6565 36.1005C16.6577 34.9783 17.1059 33.9024 17.9027 33.1088C18.6995 32.3153 19.7798 31.8688 20.9068 31.8674C21.4673 31.8661 22.0224 31.9759 22.5398 32.1905C23.0573 32.4051 23.5266 32.7201 23.9205 33.1172C24.2783 33.4744 24.5688 33.8926 24.7782 34.352C25.0296 34.9009 25.159 35.4973 25.1576 36.1005C25.1564 37.2229 24.7081 38.2988 23.9112 39.0924C23.1143 39.886 22.0338 40.3324 20.9068 40.3336V40.3336Z"
                fill="#005670"
            />
            <path
                d="M36.3024 22.3877C35.4676 22.3886 34.6673 22.7192 34.077 23.307C33.4868 23.8948 33.1548 24.6918 33.1538 25.5231C33.1528 25.9695 33.2485 26.4109 33.4343 26.8172C33.5897 27.1577 33.8052 27.4676 34.0705 27.7323C34.3623 28.0265 34.71 28.2598 35.0933 28.4188C35.4765 28.5778 35.8877 28.6593 36.3029 28.6584C36.9181 28.6583 37.5197 28.4788 38.0336 28.1421C38.5475 27.8054 38.9511 27.3262 39.1947 26.7636C39.3438 26.4208 39.4294 26.054 39.4474 25.6809C39.4474 25.6233 39.4514 25.572 39.4514 25.5231C39.4505 24.6918 39.1185 23.8948 38.5283 23.307C37.938 22.7192 37.1377 22.3886 36.3029 22.3877H36.3024ZM36.3024 29.7562C35.742 29.7574 35.1869 29.6476 34.6696 29.433C34.1522 29.2184 33.683 28.9034 33.2892 28.5064C32.9312 28.1492 32.6405 27.731 32.4309 27.2716C32.1798 26.7226 32.0504 26.1263 32.0515 25.5231C32.0529 24.4008 32.5012 23.325 33.2981 22.5315C34.095 21.738 35.1754 21.2917 36.3024 21.2905C37.4292 21.2919 38.5095 21.7382 39.3064 22.5317C40.1033 23.3251 40.5517 24.4009 40.5532 25.5231C40.5532 25.5899 40.5532 25.659 40.5474 25.7338C40.5231 26.239 40.4073 26.7355 40.2056 27.1996C39.8767 27.9587 39.3318 28.6052 38.6382 29.0595C37.9445 29.5139 37.1326 29.756 36.3024 29.7562V29.7562Z"
                fill="#005670"
            />
            <path
                d="M51.6971 25.951C51.0819 25.9514 50.4803 26.1311 49.9665 26.4679C49.4527 26.8047 49.049 27.2839 48.8054 27.8464C48.6562 28.189 48.5708 28.5556 48.5532 28.9286C48.5532 28.9862 48.5492 29.0374 48.5492 29.0864C48.5501 29.9177 48.8821 30.7146 49.4724 31.3024C50.0626 31.8902 50.8629 32.2208 51.6977 32.2218C52.5324 32.2208 53.3327 31.8902 53.923 31.3024C54.5133 30.7146 54.8453 29.9177 54.8462 29.0864C54.8471 28.4642 54.6608 27.8559 54.3112 27.3402C54.1012 27.0295 53.8374 26.7584 53.5322 26.5396C52.9985 26.1554 52.3563 25.9493 51.6977 25.951H51.6971ZM51.6971 33.3195C50.5701 33.3183 49.4896 32.8719 48.6927 32.0783C47.8958 31.2847 47.4475 30.2087 47.4463 29.0864C47.4463 29.0196 47.4463 28.9482 47.4515 28.8744C47.476 28.3699 47.5917 27.8739 47.7933 27.4104C48.1222 26.6512 48.6672 26.0045 49.3609 25.5501C50.0546 25.0957 50.8668 24.8534 51.6971 24.8533C52.587 24.8514 53.4547 25.13 54.1759 25.6492C54.5868 25.9436 54.9419 26.3085 55.2244 26.7268C55.6972 27.4241 55.9491 28.2467 55.9474 29.0881C55.9461 30.2103 55.498 31.2862 54.7012 32.0798C53.9044 32.8734 52.824 33.3198 51.6971 33.3212V33.3195Z"
                fill="#005670"
            />
            <path
                d="M20.9066 34.8072C20.5623 34.8076 20.2322 34.9441 19.9888 35.1866C19.7454 35.4291 19.6085 35.7578 19.6082 36.1007C19.6085 36.4435 19.7454 36.7722 19.9888 37.0147C20.2322 37.2571 20.5623 37.3934 20.9066 37.3937C21.2509 37.3934 21.581 37.2571 21.8245 37.0147C22.0681 36.7723 22.2051 36.4436 22.2055 36.1007C22.2052 35.7577 22.0683 35.4289 21.8248 35.1864C21.5812 34.9439 21.251 34.8075 20.9066 34.8072V34.8072ZM20.9066 38.4931C20.2701 38.4924 19.6599 38.2403 19.2099 37.7921C18.7598 37.3439 18.5066 36.7363 18.5059 36.1024C18.5065 35.4685 18.7596 34.8607 19.2097 34.4124C19.6597 33.9641 20.27 33.7119 20.9066 33.7112C21.5432 33.7119 22.1535 33.9641 22.6037 34.4124C23.0539 34.8607 23.3071 35.4685 23.3079 36.1024C23.3071 36.7364 23.0538 37.3441 22.6037 37.7923C22.1535 38.2405 21.5431 38.4925 20.9066 38.4931V38.4931Z"
                fill="#005670"
            />
            <path
                d="M36.3022 24.23C35.9579 24.2303 35.6277 24.3666 35.3842 24.609C35.1407 24.8514 35.0037 25.1801 35.0032 25.523C35.0035 25.866 35.1405 26.1948 35.384 26.4374C35.6275 26.6799 35.9578 26.8163 36.3022 26.8166C36.6465 26.8161 36.9765 26.6797 37.22 26.4372C37.4634 26.1946 37.6002 25.8659 37.6006 25.523C37.6002 25.1802 37.4634 24.8515 37.2199 24.6091C36.9765 24.3667 36.6464 24.2303 36.3022 24.23V24.23ZM36.3022 27.916C35.6655 27.9153 35.0552 27.6631 34.605 27.2148C34.1549 26.7665 33.9016 26.1587 33.9009 25.5247C33.9016 24.8908 34.1549 24.2831 34.6051 23.8349C35.0553 23.3867 35.6656 23.1346 36.3022 23.134C36.9386 23.1348 37.5488 23.3869 37.9989 23.8351C38.4489 24.2833 38.7021 24.8909 38.7029 25.5247C38.7023 26.1587 38.4492 26.7665 37.9991 27.2148C37.549 27.6631 36.9387 27.9153 36.3022 27.916V27.916Z"
                fill="#005670"
            />
            <path
                d="M51.6972 27.7939C51.3528 27.7942 51.0226 27.9306 50.779 28.1731C50.5355 28.4156 50.3985 28.7445 50.3982 29.0874C50.3985 29.4304 50.5355 29.7593 50.779 30.0018C51.0226 30.2443 51.3528 30.3807 51.6972 30.381C52.0415 30.3805 52.3716 30.2441 52.615 30.0016C52.8584 29.7591 52.9953 29.4303 52.9956 29.0874C52.9953 28.7446 52.8584 28.4158 52.615 28.1733C52.3716 27.9308 52.0415 27.7944 51.6972 27.7939V27.7939ZM51.6972 31.4799C51.0606 31.4793 50.4503 31.2272 50.0001 30.779C49.5499 30.3308 49.2967 29.7231 49.2959 29.0892C49.2967 28.4553 49.5499 27.8475 50.0001 27.3993C50.4503 26.9511 51.0606 26.6991 51.6972 26.6985C52.3337 26.6992 52.9438 26.9514 53.3939 27.3995C53.844 27.8477 54.0971 28.4554 54.0979 29.0892C54.0971 29.723 53.844 30.3306 53.3939 30.7788C52.9438 31.227 52.3337 31.4791 51.6972 31.4799V31.4799Z"
                fill="#005670"
            />
            <path
                d="M67.0921 12.9397C66.7478 12.9401 66.4176 13.0765 66.1741 13.319C65.9306 13.5615 65.7936 13.8903 65.7932 14.2332C65.7936 14.5761 65.9306 14.9049 66.1741 15.1474C66.4176 15.3898 66.7478 15.5263 67.0921 15.5267C67.4365 15.5263 67.7666 15.3898 68.0101 15.1474C68.2536 14.9049 68.3906 14.5761 68.3911 14.2332C68.3906 13.8903 68.2536 13.5615 68.0101 13.319C67.7666 13.0765 67.4365 12.9401 67.0921 12.9397V12.9397ZM67.0921 16.6256C66.4556 16.6248 65.8455 16.3727 65.3954 15.9246C64.9453 15.4764 64.6922 14.8687 64.6914 14.2349C64.6922 13.6011 64.9453 12.9935 65.3954 12.5453C65.8455 12.0971 66.4556 11.845 67.0921 11.8442C67.7286 11.845 68.3388 12.0971 68.7888 12.5453C69.2389 12.9935 69.4921 13.6011 69.4928 14.2349C69.4921 14.8687 69.2389 15.4764 68.7888 15.9246C68.3388 16.3727 67.7286 16.6248 67.0921 16.6256V16.6256Z"
                fill="#005670"
            />
            <path
                d="M47.9472 28.872L39.77 26.9242L40.0262 25.8564L48.2034 27.8048L47.9472 28.872Z"
                fill="#005670"
            />
            <path
                d="M54.7929 26.975L54.022 26.1905L64.0592 16.4106L64.8301 17.1951L54.7929 26.975Z"
                fill="#005670"
            />
            <path
                d="M24.1315 34.5687H23.9597V34.0192L23.6462 33.5683L32.9443 27.1633L33.5712 28.0664L24.1315 34.5687Z"
                fill="#005670"
            />
            <rect x="63" y="21" width="6" height="32" fill="#E3EBEF" />
            <rect x="32" y="32" width="6" height="21" fill="#E3EBEF" />
            <path
                d="M63.3331 21.4967C63.3209 21.4967 63.3093 21.5014 63.3008 21.51C63.2922 21.5185 63.2874 21.5301 63.2874 21.5421V52.8573C63.2874 52.8694 63.2922 52.8809 63.3008 52.8895C63.3093 52.898 63.3209 52.9028 63.3331 52.9028H70.8516C70.8637 52.9028 70.8753 52.898 70.8839 52.8895C70.8924 52.8809 70.8972 52.8694 70.8972 52.8573V21.5421C70.8972 21.5301 70.8924 21.5185 70.8839 21.51C70.8753 21.5014 70.8637 21.4967 70.8516 21.4967H63.3331ZM70.8516 54.0005H63.3331C63.0287 54.0002 62.7369 53.8797 62.5216 53.6654C62.3064 53.451 62.1854 53.1604 62.1851 52.8573V21.5421C62.1854 21.239 62.3064 20.9484 62.5216 20.7341C62.7369 20.5198 63.0287 20.3992 63.3331 20.3989H70.8516C71.1559 20.3992 71.4478 20.5198 71.663 20.7341C71.8782 20.9484 71.9993 21.239 71.9996 21.5421V52.8573C71.9993 53.1604 71.8782 53.451 71.663 53.6654C71.4478 53.8797 71.1559 54.0002 70.8516 54.0005V54.0005Z"
                fill="#005670"
            />
            <path
                d="M32.543 32.7867C32.5309 32.7867 32.5193 32.7915 32.5107 32.8C32.5022 32.8085 32.4973 32.8201 32.4973 32.8322V52.8573C32.4973 52.8694 32.5022 52.881 32.5107 52.8895C32.5193 52.8981 32.5309 52.9028 32.543 52.9028H40.0615C40.0736 52.9028 40.0853 52.8981 40.0938 52.8895C40.1024 52.881 40.1072 52.8694 40.1072 52.8573V32.8322C40.1072 32.8201 40.1024 32.8085 40.0938 32.8C40.0853 32.7915 40.0736 32.7867 40.0615 32.7867H32.543ZM40.0615 54.0006H32.543C32.2387 54.0003 31.9468 53.8797 31.7316 53.6654C31.5164 53.4511 31.3953 53.1605 31.395 52.8573V32.8322C31.3953 32.5291 31.5164 32.2385 31.7316 32.0241C31.9468 31.8098 32.2387 31.6893 32.543 31.689H40.0615C40.3658 31.6894 40.6575 31.81 40.8726 32.0243C41.0877 32.2387 41.2086 32.5292 41.209 32.8322V52.8573C41.2088 53.1604 41.0879 53.451 40.8727 53.6654C40.6576 53.8797 40.3659 54.0003 40.0615 54.0006Z"
                fill="#005670"
            />
            <path
                d="M23.3672 14.1824C23.3672 13.9485 23.2977 13.7198 23.1675 13.5253C23.0374 13.3309 22.8525 13.1793 22.6362 13.0899C22.4199 13.0004 22.1818 12.9771 21.9522 13.0228C21.7227 13.0686 21.5118 13.1813 21.3463 13.3468C21.1808 13.5123 21.0682 13.7231 21.0226 13.9526C20.9771 14.182 21.0006 14.4198 21.0903 14.6359C21.18 14.852 21.3319 15.0367 21.5266 15.1665C21.7213 15.2964 21.9502 15.3656 22.1843 15.3655C22.4982 15.3653 22.7991 15.2406 23.0209 15.0187C23.2427 14.7968 23.3673 14.496 23.3672 14.1824V14.1824Z"
                fill="#F15B4B"
            />
        </svg>
    );
}
